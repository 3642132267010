import Cookies from 'js-cookie';

export function useAppPlatform() {
    const getCurrentPlatform = () => Cookies.get('app-platform');
    const isMobileApp = () => ['android', 'ios'].includes(getCurrentPlatform());

    return {
        getCurrentPlatform,
        isMobileApp,
    };
}
