import {
    faAdd,
    faAddressCard,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowDown,
    faArrowDownRight,
    faArrowDownToLine,
    faArrowLeft,
    faArrowRight,
    faArrowRightFromBracket as farArrowRightFromBracket,
    faArrowRightFromArc,
    faArrowRotateLeft,
    faArrowUp,
    faArrowUpRight,
    faArrowUpRightFromSquare,
    faArrowsRotate,
    faBank,
    faBars,
    faBattery,
    faBell as farBell,
    faBriefcase as farBriefcase,
    faBriefcaseMedical as farBriefcaseMedical,
    faBook as farBook,
    faBuilding,
    faCalculator as farCalculator,
    faCalculatorSimple as farCalculatorSimple,
    faCalendar as farCalendar,
    faCalendarXmark,
    faCalendars as farCalendars,
    faCar,
    faChartLine as farChartLine,
    faCheck,
    faCheckDouble,
    faCircle as farCircle,
    faCircleCheck as farCircleCheck,
    faCircleEllipsis,
    faCircleExclamation as farCircleExclamation,
    faCircleInfo as farCircleInfo,
    faCircleQuestion,
    faClipboardList as farClipboardList,
    faClock,
    faClockThree,
    faClose,
    faCoin,
    faCoins as farCoins,
    faCommentDots,
    faCopy,
    faDollar,
    faDownload,
    faEdit,
    faEllipsis,
    faEnvelope,
    faEnvelopesBulk,
    faEuroSign,
    faEye,
    faEyeSlash,
    faEquals as farEquals,
    faFloppyDisk as farFloppyDisk,
    faFile,
    faFileAlt,
    faFileCheck,
    faFileContract,
    faFileCsv,
    faFileDoc,
    faFileImport,
    faFileInvoice as farFileInvoice,
    faFileLines as farFileLines,
    faFileMagnifyingGlass as farFileMagnifyingGlass,
    faFilePlus,
    faFilePdf,
    faFileZip as farFileZip,
    faFiles as farFiles,
    faFileSpreadsheet,
    faFileXmark,
    faFolder as farFolder,
    faFolderOpen,
    faFolders as farFolders,
    faGear as farGear,
    faGlobe as farGlobe,
    faGavel,
    faHand,
    faHandHoldingDollar as farHandHoldingDollar,
    faHandWave as farHandWave,
    faHotel,
    faHourglassHalf as farHourglassHalf,
    faHouse as farHouse,
    faHouseBlank,
    faIdCard as farIdCard,
    faImage,
    faIslandTropical,
    faKey,
    faKey as farKey,
    faLaptopMobile as farLaptopMobile,
    faLink,
    faList as farList,
    faLoader,
    faLock,
    faLockOpen,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faMinusCircle as farMinusCircle,
    faPartyHorn,
    faPaperclip,
    faPen,
    faPenField as farPenField,
    faPercent,
    faPlane as farPlane,
    faPlusCircle as farPlusCircle,
    faPhone,
    faPizzaSlice as farPizzaSlice,
    faPlus,
    faPowerOff,
    faPercentage as farPercentage,
    faRepeat,
    faFileSignature,
    faFiles,
    faPuzzlePiece,
    faRocket as farRocket,
    faRotate,
    faScaleBalanced,
    faSend,
    faShieldCheck,
    faSidebar as farSidebar,
    faSignature,
    faSliders,
    faSliders as farSliders,
    faSmileWink as farSmileWink,
    faSms,
    faStar as farStar,
    faStethoscope,
    faSuitcase,
    faTrash,
    faTriangleExclamation as farTriangleExclamation,
    faUndo as farUndo,
    faUpload,
    faUser as farUser,
    faUserCheck as farUserCheck,
    faUserClock as farUserClock,
    faUserGraduate as farUserGraduate,
    faUserGroup as farUserGroup,
    faUserPlus as farUserPlus,
    faUserTie as farUserTie,
    faUserVneckHair as farUserVneckHair,
    faUserXmark as farUserXmark,
    faUsers,
    faUsers as farUsers,
    faUserMagnifyingGlass as farUserMagnifyingGlass,
    faUserPen,
    faUserSlash as farUserSlash,
    faUtensils,
    faRobot as farRobot,
    faXmark,
    faXmarkToSlot,
    faInputText as farInputText,
    faInputNumeric as farInputNumeric,
    faCalendarWeek as farCalendarWeek,
    faToggleOn as farToggleOn,
    faListDropdown as farListDropdown,
    faSackDollar,
    faUserHelmetSafety as farUserHelmetSafety,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faDumbbell,
    faArrowUpFromLine as farArrowUpFromLine,
    faArrowDownFromLine as farArrowDownFromLine,
    faLightbulb,
    faUserXmark,
    faExternalLink,
    faNote,
    faThumbtack,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faArrowRightArrowLeft,
    faArrowRightFromBracket as fasArrowRightFromBracket,
    faBell as fasBell,
    faBook as fasBook,
    faBriefcase as fasBriefcase,
    faBuilding as fasBuilding,
    faCalculator as fasCalculator,
    faCalendars as fasCalendars,
    faCaretDown,
    faCaretUp,
    faChartLine as fasChartLine,
    faCircleCheck as fasCircleCheck,
    faCircleExclamation as fasCircleExclamation,
    faCircleInfo as fasCircleInfo,
    faCircleXmark as fasCircleXmark,
    faClock as fasClock,
    faCoins as fasCoins,
    faFileCheck as fasFileCheck,
    faFileLines as fasFileLines,
    faFolder as fasFolder,
    faGear as fasGear,
    faHouse as fasHouse,
    faKey as fasKey,
    faLaptopMobile as fasLaptopMobile,
    faList as fasList,
    faMars,
    faPenField as fasPenField,
    faPizzaSlice as fasPizzaSlice,
    faRocket as fasRocket,
    faSliders as fasSliders,
    faScaleBalanced as fasScaleBalanced,
    faTriangleExclamation as fasTriangleExclamation,
    faUserGroup as fasUserGroup,
    faUserPlus as fasUserPlus,
    faUser as fasUser,
    faUsers as fasUsers,
    faPuzzlePiece as fasPuzzlePiece,
    faFileSignature as fasFileSignature,
    faFiles as fasFiles,
    faInputText as fasInputText,
    faInputNumeric as fasInputNumeric,
    faCalendarWeek as fasCalendarWeek,
    faToggleOn as fasToggleOn,
    faListDropdown as fasListDropdown,
    faShieldCheck as fasShieldCheck,
    faUserHelmetSafety as fasUserHelmetSafety,
    faVenus,
    faArrowUpFromLine as fasArrowUpFromLine,
    faArrowDownFromLine as fasArrowDownFromLine,
    faPen as fasPen,
    faThumbtack as fasThumbtack,
} from '@fortawesome/pro-solid-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';

library.add(
    faAdd,
    faAddressCard,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowDown,
    faArrowDownRight,
    faArrowDownToLine,
    faArrowLeft,
    faArrowRight,
    faArrowRightArrowLeft,
    faArrowRotateLeft,
    faArrowUp,
    faArrowUpRight,
    faArrowUpRightFromSquare,
    faArrowsRotate,
    faBank,
    faBars,
    faBattery,
    farBell,
    fasBell,
    faBuilding,
    faCalendarXmark,
    faCar,
    faCaretDown,
    faCaretUp,
    faCheck,
    faCheckDouble,
    faCircleEllipsis,
    faCircleQuestion,
    faClock,
    faClockThree,
    faClose,
    faCoin,
    faCommentDots,
    faCopy,
    faDollar,
    faDownload,
    faEdit,
    faEllipsis,
    faEnvelope,
    faEnvelopesBulk,
    faEuroSign,
    faEye,
    faEyeSlash,
    faFileSignature,
    faFiles,
    faPuzzlePiece,
    faFile,
    faFileAlt,
    faFileCheck,
    faFileContract,
    faFileCsv,
    faFileDoc,
    faFileImport,
    faFilePlus,
    faFilePdf,
    faFileSpreadsheet,
    faFolderOpen,
    faFileXmark,
    faHand,
    faHotel,
    faHouseBlank,
    faImage,
    faIslandTropical,
    faKey,
    faLink,
    faLoader,
    faLock,
    faLockOpen,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faMars,
    faPartyHorn,
    faPaperclip,
    faPen,
    farPercentage,
    farPenField,
    faPercent,
    faPhone,
    faPlus,
    faPowerOff,
    faRepeat,
    faRotate,
    faScaleBalanced,
    faSend,
    faShieldCheck,
    fasShieldCheck,
    faSliders,
    faSms,
    faStethoscope,
    faSuitcase,
    faTrash,
    faUpload,
    faUsers,
    faUserPen,
    faUtensils,
    faVenus,
    faXmark,
    faXmarkToSlot,
    faSackDollar,
    farUserHelmetSafety,
    farArrowRightFromBracket,
    farBriefcase,
    farBriefcaseMedical,
    farBook,
    farCalendars,
    farCalculator,
    farCalculatorSimple,
    farCalendar,
    farChartLine,
    farCircle,
    farCircleCheck,
    farCircleExclamation,
    farCircleInfo,
    farClipboardList,
    farCoins,
    farFileInvoice,
    farFileLines,
    farFileMagnifyingGlass,
    farFileZip,
    farFiles,
    farFloppyDisk,
    farFolder,
    farFolders,
    farGear,
    farGlobe,
    farEquals,
    faGavel,
    farHandHoldingDollar,
    farHandWave,
    farHourglassHalf,
    farHouse,
    farIdCard,
    farKey,
    farLaptopMobile,
    farList,
    farMinusCircle,
    farPizzaSlice,
    farPlane,
    farPlusCircle,
    farRobot,
    farRocket,
    farSidebar,
    faSignature,
    farSliders,
    farSmileWink,
    farTriangleExclamation,
    farUndo,
    farUser,
    farUserCheck,
    farUserClock,
    farUserGraduate,
    farUserGroup,
    farUserMagnifyingGlass,
    farUserPlus,
    farUserTie,
    farUserVneckHair,
    farUsers,
    farUserSlash,
    farUserXmark,
    farInputText,
    farInputNumeric,
    farCalendarWeek,
    farToggleOn,
    farListDropdown,
    farStar,
    fasArrowRightFromBracket,
    fasBuilding,
    fasBriefcase,
    fasBook,
    fasCalendars,
    fasCalculator,
    fasChartLine,
    fasCircleCheck,
    fasCircleExclamation,
    fasCircleInfo,
    fasCircleXmark,
    fasClock,
    fasCoins,
    fasFileCheck,
    fasFileLines,
    fasFiles,
    fasFolder,
    fasGear,
    fasHouse,
    fasKey,
    fasLaptopMobile,
    fasList,
    fasPenField,
    fasPizzaSlice,
    fasRocket,
    fasSliders,
    fasScaleBalanced,
    fasTriangleExclamation,
    fasUserGroup,
    fasUserPlus,
    fasUser,
    fasUsers,
    fasPuzzlePiece,
    fasFileSignature,
    fasInputText,
    fasInputNumeric,
    fasCalendarWeek,
    fasToggleOn,
    fasListDropdown,
    fasUserHelmetSafety,
    fasFiles,
    faArrowRightFromArc,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faDumbbell,
    fasArrowUpFromLine,
    farArrowUpFromLine,
    farArrowDownFromLine,
    fasArrowDownFromLine,
    faLightbulb,
    faUserXmark,
    faExternalLink,
    faNote,
    fasPen,
    faThumbtack,
    fasThumbtack,
);
