const ACCOUNTANT_ROLE_LABEL = {
    STANDARD: 'Commercialista',
    COLLABORATOR: 'Collaboratore',
};

const ACCOUNTANT_ROLE = {
    STANDARD: 'STANDARD',
    COLLABORATOR: 'COLLABORATOR',
};

const ACCOUNTANT_NOTIFICATION_TEMPLATE = {
    ACCOUNTANT_DOCUMENTS_READY: 'ACCOUNTANT_DOCUMENTS_READY',
    ACCOUNTANT_DOCUMENTS_UPDATED: 'ACCOUNTANT_DOCUMENTS_UPDATED',
};

export {ACCOUNTANT_ROLE_LABEL, ACCOUNTANT_ROLE, ACCOUNTANT_NOTIFICATION_TEMPLATE};
