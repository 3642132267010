import {afterEachHandler, beforeEachHandler, commonRoutes} from '@jetCommon/router.js';
import {api} from '@/api';
import {createRouter, createWebHashHistory} from 'vue-router';
import {scrollBehavior} from '@jetCommon/router.js';
import {useSpaStore} from '@/stores/spa.js';
import settings from '@/env.js';

const routes = [
    ...commonRoutes,
    {
        path: '/registrazione/:specialLinkCode',
        component: () => import('@/features/first-access/views/FirstAccessView.vue'),
        meta: {requiresAuth: false},
        name: 'firstAccessView',
    },
    {
        path: `/:${settings.JET_ROUTE_ENTITY_NAME}?`,
        component: () => import('@/layouts/SidebarLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/features/notifications/views/NotificationsView.vue'),
                name: 'dashboardView',
            },
            {
                path: 'clienti',
                component: () => import('@/features/company/views/CompaniesView.vue'),
                name: 'companiesView',
            },
            {
                path: 'clienti/:companyId/',
                component: () => import('@/features/company/views/CompanyDocumentsView.vue'),
                name: 'companyDocumentsView',
            },
            {
                path: 'clienti/:companyId/cedolini',
                component: () => import('@/features/company/views/payroll/PayrollsView.vue'),
                name: 'payrollsView',
            },
            {
                path: 'clienti/:companyId/cedolini/:payrollPeriod',
                component: () => import('@/features/company/views/payroll/PayrollPayslipsView.vue'),
                name: 'payrollPayslipsView',
            },
            {
                path: 'impostazioni',
                component: () => import('@/features/accountant/views/AccountantSettingsView.vue'),
                name: 'settingsView',
                meta: {requiresStandardAccountantRole: true},
            },
            {
                path: 'impostazioni-studio',
                component: () => import('@/features/settings/views/AccountingOfficeSettingsView.vue'),
                name: 'accountingOfficeSettingsView',
                meta: {requiresStandardAccountantRole: true},
            },
            {
                path: 'impostazioni-studio/admin',
                component: () => import('@/features/settings/views/AccountingOfficeAdminsView.vue'),
                name: 'accountingOfficeAdminsView',
                meta: {requiresStandardAccountantRole: true},
            },
            {
                path: 'impostazioni-studio/collaboratori',
                component: () => import('@/features/settings/views/AccountingOfficeCollaboratorsView.vue'),
                name: 'accountingOfficeCollaboratorsView',
                meta: {requiresStandardAccountantRole: true},
            },
            {
                path: 'costo-assunzione',
                component: () => import('@/features/hiring-cost-simulator/views/HiringCostSimulatorView.vue'),
                name: 'hiringCostView',
            },
            {
                path: 'clienti/:companyId/rimborsi-spese',
                component: () => import('@/features/company/views/CompanyExpensesView.vue'),
                name: 'expensesView',
            },
            {
                path: 'clienti/:companyId/report',
                redirect: {name: 'reportEmployeesView'},
                children: [
                    {
                        path: 'personale',
                        component: () => import('@/features/company/views/report/ReportEmployeesView.vue'),
                        meta: {disableOnMobileWidths: true},
                        name: 'reportEmployeesView',
                    },
                    {
                        path: 'ferie-e-permessi',
                        component: () => import('@/features/company/views/report/ReportAbsenceRequestsView.vue'),
                        meta: {disableOnMobileWidths: true},
                        name: 'reportAbsenceRequestsView',
                    },
                    {
                        path: 'rimborsi-spese',
                        component: () => import('@/features/company/views/report/ReportExpensesView.vue'),
                        meta: {disableOnMobileWidths: true},
                        name: 'reportExpensesView',
                    },
                    {
                        path: 'costo-aziendale',
                        component: () => import('@/features/company/views/report/ReportBusinessCostView.vue'),
                        meta: {disableOnMobileWidths: true},
                        name: 'reportBusinessCostView',
                    },
                    {
                        path: 'tfr-azienda',
                        component: () => import('@/features/company/views/report/ReportCompanyTfrView.vue'),
                        meta: {disableOnMobileWidths: true},
                        name: 'reportCompanyTfrView',
                    },
                    {
                        path: 'export-dati',
                        component: () => import('@/features/company/views/report/ReportDataExportView.vue'),
                        meta: {disableOnMobileWidths: true},
                        name: 'reportDataExportView',
                    },
                ],
            },
        ],
    },
];

const router = createRouter({
    scrollBehavior,
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(
    async (to, from) => await beforeEachHandler(to, from, api, useSpaStore, settings.JET_ROUTE_ENTITY_NAME),
);

router.afterEach(afterEachHandler);

export default router;
