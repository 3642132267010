import {createSharedComposable, useWindowSize} from '@vueuse/core';

const useSharedWindowSize = createSharedComposable(useWindowSize);

export function useDesignSystemTools() {
    const {width, height} = useSharedWindowSize();

    const smBreakpoint = 480;
    const mdBreakpoint = 768;
    const lgBreakpoint = 1080;

    const isXS = computed(() => width.value < smBreakpoint);
    const isSM = computed(() => width.value >= smBreakpoint && width.value < mdBreakpoint);
    const isMD = computed(() => width.value >= mdBreakpoint && width.value < lgBreakpoint);
    const isLG = computed(() => width.value >= lgBreakpoint);
    const isMobile = computed(() => isXS.value || isSM.value);

    // Utilities for media queries
    const isSmAndUp = computed(() => isSM.value || isMD.value || isLG.value);
    const isMdAndUp = computed(() => isMD.value || isLG.value);
    const isLgAndUp = computed(() => isLG.value);

    const isSmAndDown = computed(() => isXS.value || isSM.value);
    const isMdAndDown = computed(() => isXS.value || isSM.value || isMD.value);
    const isLgAndDown = computed(() => isXS.value || isSM.value || isMD.value || isLG.value);

    const showSidebar = computed(() => isLG.value);
    const showHeader = computed(() => !showSidebar.value);
    const showViewDetailFullScreen = computed(() => !isLG.value);
    const showDialogsFullScreen = computed(() => !isLG.value);
    const tableColumnsUnfixed = computed(() => isXS.value || isSM.value);

    return {
        isXS,
        isSM,
        isMD,
        isLG,
        isSmAndUp,
        isMdAndUp,
        isLgAndUp,
        isSmAndDown,
        isMdAndDown,
        isLgAndDown,
        isMobile,
        windowWidth: width,
        windowHeight: height,
        showSidebar,
        showHeader,
        showViewDetailFullScreen,
        showDialogsFullScreen,
        tableColumnsUnfixed,
    };
}
