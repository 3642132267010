const CAPABILITY = {
    CAN_CREATE: 'can_create',
    CAN_UPDATE: 'can_update',
};

const RESOURCE = {
    ABSENCE_REQUESTS: 'absencerequest',
    EXPENSES: 'expense',
    SALARY: 'salary',
    NOTIFICATION_SETTINGS: 'notificationsetting',
    ABSENCE_CALENDAR: 'absencecalendar',
};

export {CAPABILITY, RESOURCE};
