import {defineStore} from 'pinia';

export const useJetBuildVersionStore = defineStore('jetBuildVersion', () => {
    const buildVersion = ref('');
    function updateBuildVersion(version) {
        if (version) {
            buildVersion.value = version;
        }
    }
    return {buildVersion, updateBuildVersion};
});
