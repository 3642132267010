import {ApiBase} from '@jetCommon/api/base.js';

export default class SetupApiCommon extends ApiBase {
    static resourceName = 'setup';

    getPersonActivationData(specialLinkCode) {
        return this.apiGet('get_person_activation_data/', {
            headers: {
                ...this.getSpecialLinkCodeRequestHeader(specialLinkCode),
            },
        });
    }

    processPersonActivation(specialLinkCode, data) {
        return this.apiPost('process_person_activation/', data, {
            headers: {
                ...this.getSpecialLinkCodeRequestHeader(specialLinkCode),
            },
        });
    }
}
