<script setup>
    import 'element-plus/es/components/message/style/index';
    import {useDesignSystemTools} from '@jetCommon/composables/design-system-tools';

    import JetCol from '@jetDS/components/JetCol.vue';
    import JetRow from '@jetDS/components/JetRow.vue';

    defineProps({
        message: {
            type: String,
            default: '',
        },
        actionLabel: {
            type: String,
            default: '',
        },
    });

    const {isSmAndDown} = useDesignSystemTools();
</script>

<template>
    <JetRow v-if="actionLabel" align="middle" class="jet-message base" :class="isSmAndDown ? 'pr-20' : 'pr-8'">
        <JetCol :span="18">
            <div class="jet-message__text medium">
                {{ message }}
            </div>
        </JetCol>
        <JetCol :span="6">
            <div class="jet-message__action semibold">
                {{ actionLabel }}
            </div>
        </JetCol>
    </JetRow>
    <JetRow v-else align="middle" class="jet-message base" :class="isSmAndDown ? 'pr-20' : 'pr-8'">
        <JetCol :span="24">
            <div class="jet-message__text medium">
                {{ message }}
            </div>
        </JetCol>
    </JetRow>
</template>

<style scoped lang="scss">
    @use '@jetDS/scss/_media-queries.scss' as *;

    .jet-message {
        min-width: var(--jet-message-min-width);

        &__action {
            height: 100%;
            text-align: end;
            vertical-align: middle;
        }

        &__text {
            white-space: pre-line;
        }

        @include media-query('sm-and-down') {
            min-width: 350px;
        }
    }
</style>

<!-- This style section is deliberately not scoped due to limitations in wrapping the ElMessage component -->
<style lang="scss">
    .jet-message-default--plain {
        background-color: var(--el-color-primary-light-9);
        color: var(--el-color-black);

        .el-message__closeBtn {
            color: var(--el-color-black);
        }
    }
    .jet-message-default--solid {
        background-color: var(--el-color-primary);
        color: var(--el-color-white);

        .el-message__closeBtn {
            color: var(--el-color-white);
        }
    }
    .jet-message-info--plain {
        background-color: var(--jet-color-blue-light-9);
        color: var(--el-color-black);

        .el-message__closeBtn {
            color: var(--el-color-black);
        }

        .el-message__icon {
            color: var(--jet-color-blue);
        }
    }
    .jet-message-info--solid {
        background-color: var(--jet-color-blue);
        color: var(--el-color-white);

        .el-message__closeBtn {
            color: var(--el-color-white);
        }
    }
    .jet-message-success--plain {
        background-color: var(--el-color-success-light-9);
        color: var(--el-color-black);

        .el-message__closeBtn {
            color: var(--el-color-black);
        }

        .el-message__icon {
            color: var(--el-color-success);
        }
    }
    .jet-message-success--solid {
        background-color: var(--el-color-success);
        color: var(--el-color-white);

        .el-message__closeBtn {
            color: var(--el-color-white);
        }
    }
    .jet-message-warning--plain {
        background-color: var(--el-color-warning-light-9);
        color: var(--el-color-black);

        .el-message__closeBtn {
            color: var(--el-color-black);
        }

        .el-message__icon {
            color: var(--el-color-warning);
        }
    }
    .jet-message-warning--solid {
        background-color: var(--el-color-warning);
        color: var(--el-color-white);

        .el-message__closeBtn {
            color: var(--el-color-white);
        }
    }
    .jet-message-error--plain {
        background-color: var(--el-color-danger-light-9);
        color: var(--el-color-black);

        .el-message__closeBtn {
            color: var(--el-color-black);
        }

        .el-message__icon {
            color: var(--el-color-danger);
        }
    }
    .jet-message-error--solid {
        background-color: var(--el-color-danger);
        color: var(--el-color-white);

        .el-message__closeBtn {
            color: var(--el-color-white);
        }
    }
</style>
