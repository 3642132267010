import SetupApiCommon from '@jetCommon/api/setup.js';

export default class SetupApi extends SetupApiCommon {
    processAccountantActivation(specialLinkCode, data) {
        return this.apiPost('process_accountant_activation/', data, {
            headers: {
                ...this.getSpecialLinkCodeRequestHeader(specialLinkCode),
            },
        });
    }
}
