import {defineStore, storeToRefs} from 'pinia';
import {useAuthStore} from './auth';

export const usePushNotificationsStore = defineStore('pushNotifications', () => {
    let api = null;
    const authStore = useAuthStore();

    function init(apiClient) {
        api = apiClient;
    }

    const token = ref(null);

    const {person} = storeToRefs(authStore);

    watch([person, token], () => {
        if (person.value && token.value) {
            const data = {token: token.value.token};
            api.personPushTokens.registerOrUpdate(data).catch(api.end);
        }
    });

    return {
        token,
        init,
    };
});
