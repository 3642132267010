import {ApiBase} from '@jetCommon/api/base.js';

export default class AccountantsApiCommon extends ApiBase {
    static resourceName = 'accountants';

    uploadAvatar(accountantId, data) {
        return this.apiPostForm(`${accountantId}/avatar/`, data);
    }

    removeAvatar(accountantId, data) {
        return this.apiDelete(`${accountantId}/avatar/`, data);
    }

    sendCompanyInvitation(accountantId, data = {}) {
        return this.apiPost(`${accountantId}/send_company_invitation/`, data);
    }

    sendInvitation(accountantId, data = {}) {
        return this.apiPost(`${accountantId}/send_invitation/`, data);
    }

    retrieveNotificationSettings(adminId) {
        return this.retrieve(`${adminId}/notification_settings`);
    }

    updateNotificationSettings(accountantId, data) {
        return this.patch(`${accountantId}/notification_settings`, data);
    }
}
