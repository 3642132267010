export default function installIntercomWidget(appId, userData) {
    // Code provided by Intercom, beautified with https://beautifier.io/,
    // modified by hand to make eslint happy and to add parameters
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
    } else {
        const d = document;
        const i = function (...args) {
            i.c(args);
        };
        i.q = [];
        i.c = function (args) {
            i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${appId}`;
            const [x] = d.getElementsByTagName('script');
            x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
            l();
        } else if (w.attachEvent) {
            w.attachEvent('onload', l);
        } else {
            w.addEventListener('load', l, false);
        }
    }
    window.Intercom('boot', {
        app_id: appId,
        ...userData,
    });
}
