const defaultQueryClientConfig = {
    defaultOptions: {
        queries: {
            retry: 1,
            staleTime: 1000 * 60 * 5,
            gcTime: 1000 * 60 * 5,
        },
    },
};

const vueQueryConfig = {
    enableDevtoolsV6Plugin: true,
    queryClientConfig: defaultQueryClientConfig,
};

export default vueQueryConfig;
