import {ApiBase} from '@jetCommon/api/base.js';

export default class EmployeeApiCommon extends ApiBase {
    static resourceName = 'employees';

    inCurrentPayroll(params) {
        return this.apiGet('in_current_payroll/', {params});
    }

    currentInForce(params) {
        return this.apiGet('current_in_force/', {params});
    }

    employeeChoices(params) {
        return this.apiGet('employee_choices/', {params});
    }

    uploadAvatar(employeeId, data) {
        return this.apiPostForm(`${employeeId}/avatar/`, data);
    }

    removeAvatar(employeeId) {
        return this.apiDelete(`${employeeId}/avatar/`);
    }

    sendInvitation(employeeId) {
        return this.apiPost(`${employeeId}/send_invitation/`);
    }

    async jobTitleChoices(params) {
        return await this.apiGet('job_title_choices/', {params});
    }

    async contractKindChoices(params) {
        return await this.apiGet('contract_kind_choices/', {params});
    }

    patchWithNotification(employeeId, data) {
        return this.apiPatch(`${employeeId}/patch_and_notify/`, data);
    }

    patchCustomFieldValues(employeeId, data) {
        return this.apiPatch(`${employeeId}/`, {custom_field_values: data});
    }

    getExportableXlsCustomFields(companyId) {
        return this.apiGet('exportable_custom_fields/', {params: {company: companyId}});
    }

    workingHoursChoices(params) {
        return this.apiGet('working_hours_choices/', {params});
    }

    whoIsOut(params) {
        return this.apiGet('is_out_now/', {params});
    }
}
