import {CAPABILITY} from '@jetCommon/constants/auth-constants.js';
import {defineStore} from 'pinia';
import {goToSSO} from '@jetCommon/helpers/navigation.js';
import {setUserOnSentry} from '@jetCommon/sentry.js';

export const useAuthStore = defineStore('auth', () => {
    let api = null;

    function init(apiClient) {
        api = apiClient;
    }

    const capabilities = ref([]);
    const person = ref(null);

    function setCapabilities(c) {
        capabilities.value = c;
    }

    function can(capability, resource) {
        return capabilities.value.includes(`${resource}.${capability}`);
    }

    function canCreate(resource) {
        return can(CAPABILITY.CAN_CREATE, resource);
    }

    function canUpdate(resource) {
        return can(CAPABILITY.CAN_UPDATE, resource);
    }

    async function fetchPerson() {
        person.value = (await api.persons.self().catch(api.end))?.data ?? null;
        if (person.value) {
            setUserOnSentry(person.value.id);
        }
    }

    const isPersonAuthenticated = computed(() => person.value !== null);

    const isImpersonationSession = computed(() => Boolean(person.value?.real_person_id));

    const hasJetRole = computed(() => isPersonAuthenticated.value && Boolean(person.value.jet_role));

    const hasManyRoles = computed(() => person.value.default_webapp_url === null);

    function logout() {
        api.auth
            .logout()
            .then(() => {
                goToSSO();
            })
            .catch(api.end);
    }

    return {
        can,
        canCreate,
        canUpdate,
        fetchPerson,
        hasJetRole,
        hasManyRoles,
        init,
        isImpersonationSession,
        isPersonAuthenticated,
        logout,
        person,
        setCapabilities,
    };
});
