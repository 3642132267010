import {ApiBase} from '@jetCommon/api/base.js';

export default class AuthApiCommon extends ApiBase {
    static resourceName = 'auth';

    checkSpaAccess(url) {
        return this.apiGet('check_spa_access/', {
            params: {url},
        });
    }

    checkSpecialLinkCode(specialLinkCode) {
        return this.apiPost(
            'check_special_link/',
            {},
            {
                headers: {
                    ...this.getSpecialLinkCodeRequestHeader(specialLinkCode),
                },
            },
        );
    }

    logout() {
        return this.apiPost('logout/');
    }

    sendResetPasswordLink(email) {
        return this.apiPost('send_reset_password_link/', {email});
    }
}
