function asInteger(valueInPx) {
    if (!valueInPx) {
        return 0;
    }

    return parseInt(valueInPx.replace('px'), 10);
}

export function useSafeAreaInset() {
    const computedStyle = getComputedStyle(document.documentElement);

    const top = asInteger(computedStyle.getPropertyValue('--jet-safe-area-inset-top'));
    const left = asInteger(computedStyle.getPropertyValue('--jet-safe-area-inset-left'));
    const right = asInteger(computedStyle.getPropertyValue('--jet-safe-area-inset-right'));
    const bottom = asInteger(computedStyle.getPropertyValue('--jet-safe-area-inset-bottom'));

    return {top, left, right, bottom};
}
