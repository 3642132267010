function capitalizeFirstLetter(string) {
    const FIRST_LETTER_INDEX = 0;
    const SLICE_INDEX_START = 1;
    return string.charAt(FIRST_LETTER_INDEX).toUpperCase() + string.slice(SLICE_INDEX_START);
}

function listToSentence(list, {separator = ', ', lastSeparator = ' e ', capitalizeFirstItem = false} = {}) {
    const _list = list.filter(item => Boolean(item));
    if (_list.length === 0) {
        return '';
    }
    if (_list.length === 1) {
        return capitalizeFirstItem ? capitalizeFirstLetter(_list[0]) : _list[0];
    }
    const lastItem = _list.splice(-1, 1);
    if (capitalizeFirstItem) {
        _list[0] = capitalizeFirstLetter(_list[0]);
    }
    return `${_list.join(separator)}${lastSeparator}${lastItem}`;
}

// TODO: delete
function getNameInitials(name) {
    return name.match(/\b(\w)/g).join('');
}

function pascalCase(text) {
    return text
        .split(/[-_\s]/)
        .map(capitalizeFirstLetter)
        .join('');
}

/**
 * Returns the appropriate word, pluralized based on count
 * @param count - the number of objects represented by the resulting word
 * @param singular - the word to use if count is 1 (singular)
 * @param plural - the word to use if count is NOT 1 (plural, 0 uses plural word)
 *
 * @example pluralize(2, 'cane', 'cani') => 'cani'
 * */
function pluralize(count, singular, plural) {
    return count === 1 ? singular : plural;
}

export {capitalizeFirstLetter, getNameInitials, listToSentence, pascalCase, pluralize};
