import {useAuthStore} from '@jetCommon/stores/auth.js';
import {useJetMessage} from '@jetCommon/composables/jet-message.js';
import {useJetRoute} from '@jetCommon/composables/jet-route.js';
import {usePushNotificationsStore} from '@jetCommon/stores/push-notifications.js';

export function initJetTools({api, routeEntityName = null, spaStore = null, enableAuthStore = false} = {}) {
    let authStore = null;
    if (enableAuthStore) {
        authStore = useAuthStore();
        authStore.init(api);

        const pushNotificationsStore = usePushNotificationsStore();
        pushNotificationsStore.init(api);
    }
    const jetMessage = useJetMessage();
    const jetRoute = routeEntityName ? useJetRoute(routeEntityName) : null;

    return {
        $api: api,
        ...(enableAuthStore && {$authStore: authStore}),
        $jetMessage: jetMessage,
        ...(routeEntityName && {$jetRoute: jetRoute}),
        ...(spaStore && {$spaStore: spaStore}),
    };
}
